define("discourse/plugins/discourse-policy/discourse/components/policy-form-field", ["exports", "@ember/helper", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PolicyFormFields = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="policy-field {{@name}}">
      <div class="policy-field-label">
        <span class="label">
          {{i18n (concat "discourse_policy.builder." @name ".label")}}
          {{#if @required}}
            <span class="required-field">*</span>
          {{/if}}
        </span>
      </div>
  
      <div class="policy-field-control">
        {{yield}}
      </div>
  
      <span class="policy-field-description">
        <span class="description">
          {{i18n (concat "discourse_policy.builder." @name ".description")}}
        </span>
      </span>
    </div>
  
  */
  {
    "id": "zNKlka3G",
    "block": "[[[1,\"\\n  \"],[10,0],[15,0,[29,[\"policy-field \",[30,1]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"policy-field-label\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"label\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[[28,[32,1],[\"discourse_policy.builder.\",[30,1],\".label\"],null]],null]],[1,\"\\n\"],[41,[30,2],[[[1,\"          \"],[10,1],[14,0,\"required-field\"],[12],[1,\"*\"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"policy-field-control\"],[12],[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,1],[14,0,\"policy-field-description\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"description\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[[28,[32,1],[\"discourse_policy.builder.\",[30,1],\".description\"],null]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@name\",\"@required\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-policy/discourse/components/policy-form-field.js",
    "scope": () => [_i18n.default, _helper.concat],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = PolicyFormFields;
});