define("discourse/plugins/discourse-policy/discourse/connectors/user-preferences-emails-pref-email-settings/preferences-policy-emails", ["exports", "@ember/helper", "truth-helpers", "discourse-common/helpers/i18n", "select-kit/components/combo-box", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _truthHelpers, _i18n, _comboBox, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EMAIL_FREQUENCY_OPTIONS = [{
    name: (0, _i18n.default)("discourse_policy.preferences.policy_emails.email_frequency_options.never"),
    value: "never"
  }, {
    name: (0, _i18n.default)("discourse_policy.preferences.policy_emails.email_frequency_options.when_away"),
    value: "when_away"
  }, {
    name: (0, _i18n.default)("discourse_policy.preferences.policy_emails.email_frequency_options.always"),
    value: "always"
  }];
  const PreferencesPolicyEmails = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <h3>{{i18n "discourse_policy.preferences.title"}}</h3>
  
    <div class="control-group policy-setting controls-dropdown">
      <label for="user_policy_email_frequency">
        {{i18n "discourse_policy.preferences.policy_emails.label"}}
      </label>
  
      <ComboBox
        @valueProperty="value"
        @content={{EMAIL_FREQUENCY_OPTIONS}}
        @value={{@outletArgs.model.user_option.policy_email_frequency}}
        @onChange={{fn
          (mut @outletArgs.model.user_option.policy_email_frequency)
        }}
        @id="user_policy_email_frequency"
      />
  
      {{#if (eq @outletArgs.model.user_option.policy_email_frequency "always")}}
        <div class="control-instructions">
          {{i18n "discourse_policy.preferences.policy_emails.always_description"}}
        </div>
      {{else if
        (eq @outletArgs.model.user_option.policy_email_frequency "when_away")
      }}
        <div class="control-instructions">
          {{i18n "discourse_policy.preferences.policy_emails.away_description"}}
        </div>
      {{/if}}
    </div>
  
  */
  {
    "id": "9k+1XAIr",
    "block": "[[[1,\"\\n  \"],[10,\"h3\"],[12],[1,[28,[32,0],[\"discourse_policy.preferences.title\"],null]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"control-group policy-setting controls-dropdown\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,\"for\",\"user_policy_email_frequency\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"discourse_policy.preferences.policy_emails.label\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[32,1],null,[[\"@valueProperty\",\"@content\",\"@value\",\"@onChange\",\"@id\"],[\"value\",[32,2],[30,1,[\"model\",\"user_option\",\"policy_email_frequency\"]],[28,[32,3],[[28,[31,0],[[30,1,[\"model\",\"user_option\",\"policy_email_frequency\"]]],null]],null],\"user_policy_email_frequency\"]],null],[1,\"\\n\\n\"],[41,[28,[32,4],[[30,1,[\"model\",\"user_option\",\"policy_email_frequency\"]],\"always\"],null],[[[1,\"      \"],[10,0],[14,0,\"control-instructions\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"discourse_policy.preferences.policy_emails.always_description\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[32,4],[[30,1,[\"model\",\"user_option\",\"policy_email_frequency\"]],\"when_away\"],null],[[[1,\"      \"],[10,0],[14,0,\"control-instructions\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"discourse_policy.preferences.policy_emails.away_description\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[\"@outletArgs\"],false,[\"mut\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-policy/discourse/connectors/user-preferences-emails-pref-email-settings/preferences-policy-emails.js",
    "scope": () => [_i18n.default, _comboBox.default, EMAIL_FREQUENCY_OPTIONS, _helper.fn, _truthHelpers.eq],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = PreferencesPolicyEmails;
});